@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

li {
  list-style: none;
  color: #fff;
}

html a {
  text-decoration: none;
  font-size: 1.02vw;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

img {
  max-width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
}

h3 {
  font-size: 2rem;
  font-weight: 400;
}

p {
  font-size: 18px;
}

.sectionpadding {
  padding: 1.8rem 40px;
}

button {
  max-width: -moz-fit-content;
  max-width: fit-content;
  background: #ed174b;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  border: none;
  padding: 8px 22px;
  border-radius: 8px;
}
button span {
  padding-right: 10px;
}

.button {
  max-width: -moz-fit-content;
  max-width: fit-content;
  background: #ed174b;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  border: none;
  padding: 8px 22px;
  border-radius: 8px;
}
.button span {
  padding-right: 10px;
}

.submit-error {
  color: #ed174b;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

.submit-success {
  width: -moz-fit-content;
  width: fit-content;
  background: #ddd;
  padding: 10px 20px;
  border-radius: 8px;
  background: rgb(230, 255, 217);
  background: linear-gradient(0deg, rgb(230, 255, 217) 0%, rgb(187, 246, 157) 100%);
  border: 1px solid rgba(9, 173, 9, 0.15);
}
.submit-success p {
  font-size: 16px;
  font-weight: 500;
  color: rgb(9, 173, 9);
  align-items: center;
  display: flex;
  gap: 10px;
  margin: 0px;
  margin-bottom: 5px;
}
.submit-success span {
  font-size: 12px;
  color: #0a3101;
  font-weight: 500;
}
.submit-success img {
  width: 30px;
}

header {
  border-bottom: 4px solid #ed174b;
  background: #1c1c1c;
  padding: 10px 40px;
}

.logoWrap img {
  width: 23vw;
}

.appMenuList {
  flex-direction: row;
  display: flex;
  gap: 50px;
  text-align: center;
  justify-content: left;
  align-items: center;
}
.appMenuList li a {
  font: 800 16px "Roboto", sans-serif;
  color: #fff;
  padding: 20px 10px;
  border-top: 5px solid #1c1c1c;
  display: flex;
  align-items: center;
  gap: 10px;
}
.appMenuList li .active {
  border-color: #fff;
}

header li.searchBar {
  position: relative;
  display: flex;
  align-items: center;
}
header li.searchBar form {
  position: relative;
}
header li.searchBar form a {
  visibility: hidden;
  position: absolute;
}
header li {
  font-weight: 800;
  position: relative;
}
header li span {
  padding-right: 10px;
}
header li:hover ul {
  visibility: visible;
}
header li a {
  color: #fff;
  font-size: 18px;
  font-weight: 800;
}
header li ul {
  position: absolute;
  top: 100%;
  background-color: #fff;
  z-index: 444;
  width: -moz-max-content;
  width: max-content;
  padding: 10px;
  left: -100%;
  visibility: hidden;
  height: fit-contents;
}
header li ul li a {
  border: none !important;
  color: #2b2a35 !important;
  font-size: 15px !important;
  justify-content: center;
  padding: 14px 10px !important;
}
header li ul input {
  width: 10vw;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 0px 10px;
  outline: none;
  color: #000;
}

.rightHeader {
  text-align: right;
}
.rightHeader li:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: right;
}

main.hero {
  position: relative;
  width: 100%;
}

.positionInherit .voicesearchForm {
  position: inherit;
}

.voicesearchForm {
  padding: 20px;
  background: none;
  position: absolute;
  width: 100%;
  bottom: 3vw;
  z-index: 222;
}
.voicesearchForm form {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  background: #fff;
  padding: 20px;
  border-radius: 11px;
}
.voicesearchForm div {
  width: 100%;
}
.voicesearchForm div select {
  -webkit-appearance: auto;
     -moz-appearance: auto;
          appearance: auto;
  height: 38px;
  line-height: 38px;
  font-weight: 600;
  color: #495057;
  font-size: 15px;
}
.voicesearchForm div input {
  width: 100%;
  height: 38px;
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 0px 10px;
  outline: none;
}
.voicesearchForm div button {
  width: 100%;
  align-items: center;
  display: flex;
}

.section_first {
  height: 70vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}
.section_first .column_first {
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/flag.webp);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: self-start;
  justify-content: left;
  padding: 15px;
  padding-top: 10vw;
}
.section_first .column_first h1 {
  font-size: 2.5rem;
  color: #fff;
  display: block;
}
.section_first .column_first h1 span {
  font-weight: 100;
  padding-left: 5px;
}
.section_first .column_second {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ed174b;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
}
.section_first .column_second .swiper {
  height: 100%;
}
.section_first .column_second .sliderImage {
  height: 100%;
  width: 100%;
}
.section_first .column_second img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 0.3s ease-in-out;
}
.section_first .column_second img:hover {
  transform: scale(1.2);
}

.logoSection {
  background: #1c1c1c;
  z-index: 222;
  position: relative;
}
.logoSection .col-md-4 {
  text-align: left;
}
.logoSection .col-md-4 img {
  width: 100%;
}

.what_we_do {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.what_we_do_box {
  display: flex;
  align-items: center;
  align-items: center;
  background: #ed174b;
  height: 100%;
  padding: 40px 40px;
}
.what_we_do_box .text_box {
  height: auto;
  background: #fff;
  border-radius: 0.25rem;
  padding: 40px;
}
.what_we_do_box .text_box h3 {
  font-size: 2.63vw;
  font-weight: 400;
}
.what_we_do_box .text_box p {
  font-size: 18px;
}

.img_box {
  text-align: center;
  background: #E5EFF9;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
.img_box img {
  width: 40vw;
}

.aboutUs .img_box {
  background: #1c1c1c;
}
.aboutUs .img_box {
  padding: 5vw 7.6vh;
}

.brand_logo {
  padding: 40px 0px;
}

.our_team_red_box {
  height: 100%;
  background: #ed174b;
  padding: 40px;
}
.our_team_red_box h3 {
  color: #fff;
}
.our_team_red_box .lets_talk {
  font-size: 1.24vw;
  position: relative;
  padding: 30px 22px 25px;
  background-color: #fff;
  z-index: 1;
  -webkit-clip-path: polygon(0 31%, 100% 31%, 100% 75%, 18% 75%, 0 88%, 0 75%, 0 75%);
          clip-path: polygon(0 31%, 100% 31%, 100% 75%, 18% 75%, 0 88%, 0 75%, 0 75%);
  font-weight: 500;
}

.title_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.our_team {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://greatbritish.b-cdn.net/wp-content//uploads/2020/07/slide_3-1.jpg);
  background-position: 15vw 90%;
  background-size: cover;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1.5rem;
  height: 100%;
}
.card .profile_img img {
  border-radius: 50%;
  max-width: 100%;
  width: 100%;
}
.card .profile_text h3 {
  color: #1c1c1c;
  width: 10.68vw;
}
.card .card_text p {
  padding-top: 1.5rem;
  font-size: 1.02vw;
}

.extra-width {
  width: 65vw;
}

footer {
  padding: 30px 40px 16px 40px;
  background-color: #1c1c1c;
}
footer .footer_details {
  padding-bottom: 30px;
}
footer .footer_details ul li {
  padding-top: 1.5rem;
  display: flex;
}
footer .footer_details ul li a {
  color: #fff;
  font-size: 1.31vw;
  font-weight: 600;
}
footer .footer_details ul li svg {
  margin-right: 10px;
}
footer .footer_right {
  text-align: right;
}
footer .footer_right .social_media_links {
  display: flex;
  justify-content: right;
  gap: 10px;
  align-items: stretch;
}
footer .footer_right .social_media_links svg {
  stroke: none;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  height: 100%;
}
footer .footer_right .term_and_condition {
  margin-top: 40px;
  display: flex;
  justify-content: right;
  gap: 10px;
}
footer .footer_right .term_and_condition li {
  font-size: 1.02vw;
}

.bottomFooter h3 {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
}
.bottomFooter .voicesList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.bottomFooter .voicesList a {
  font-size: 14px;
  margin-bottom: 9px;
}
.bottomFooter .usefullLinks {
  display: grid;
}
.bottomFooter .usefullLinks h3 {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
}
.bottomFooter .usefullLinks a {
  font-size: 20px;
  font-weight: 600;
}

.heading_panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading_panel h3 {
  color: #fff;
}

.ourProcess {
  background: #1c1c1c;
}
.ourProcess .heading_panel {
  display: inline;
  color: #fff;
}

.processBox {
  text-align: center;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  max-width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.processBox b {
  position: absolute;
  left: 10px;
  background: #fff;
  color: #2870ac;
  padding: 10px;
  border-radius: 50%;
  top: 10px;
  z-index: 2;
  font-size: 16px;
  font-weight: 600;
}
.processBox .processIcon {
  background: #2870ac;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.processBox .processText {
  position: relative;
  z-index: 2;
}
.processBox .processText p {
  color: #000;
  padding-top: 10px;
  margin: 0px auto;
  display: block;
  font-size: 16px;
}
.processBox .processText p:first-child {
  margin-bottom: 20px;
}
.processBox::after {
  width: 100%;
  height: 80px;
  background-color: #e5eff9;
  position: absolute;
  top: 0;
  z-index: 0;
  display: block;
  content: "";
  left: 0;
}

.we_supply {
  padding: 4.8rem 40px;
  padding-bottom: 40px;
}
.we_supply .heading_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.we_supply .slide_arrow {
  background: #1c1c1c;
  height: 46px;
  width: 46px;
  text-align: center;
  border: none;
  color: #fff;
  margin: 0px 5px;
  font-family: "FontAwesome";
}
.we_supply .slide_arrow i {
  font-style: normal;
}
.we_supply .slide_arrow:hover {
  background-color: #2870ac;
}

.about_supply .we_supply {
  background: #000;
}
.about_supply .we_supply h3 {
  color: #fff;
}

.we_supply_slider .card {
  text-align: center;
  background: #e5eff9;
  border: none;
  position: relative;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.175) !important;
  height: -moz-fit-content;
  height: fit-content;
}
.we_supply_slider .card .card_image {
  position: relative;
  top: 3.6vw;
}
.we_supply_slider .card .card_title {
  color: #212529 !important;
  font-size: 16px;
  margin-top: 20px;
  background: #fff;
  padding-bottom: 40px;
  margin-bottom: 0px;
  padding-top: 70px;
  font-weight: 500;
  font-family: "Space Grotesk", sans-serif;
}
.we_supply_slider .card .card_title a {
  color: #007bff;
}

.about_panel {
  padding: 0px 40px;
  height: 100vh;
}

.page_breadcrumbs {
  height: 36vw;
}
.page_breadcrumbs .row {
  height: 100%;
}
.page_breadcrumbs .text_panel_breadcrumbs {
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://greatbritish.b-cdn.net/static/css/img/flag.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 40px;
  padding-top: 6vw;
}
.page_breadcrumbs .text_panel_breadcrumbs h1 {
  font-size: 4.97vw;
  color: #fff;
  display: grid;
  font-weight: 400;
}
.page_breadcrumbs .text_panel_breadcrumbs_image {
  height: 100%;
}
.page_breadcrumbs .text_panel_breadcrumbs_image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.about_breadcrumbs {
  height: 100vh;
}

.contact_form_img {
  text-align: center;
}
.contact_form_img img {
  width: 35vw;
}

.contact_form h3 {
  margin-bottom: 30px;
}

.get_in_touch {
  padding: 40px;
  margin-bottom: 3rem;
}
.get_in_touch .footer_details {
  background-color: #ed174b;
  padding: 1.5rem;
}
.get_in_touch .footer_details ul li {
  margin-bottom: 1rem;
  display: flex;
}
.get_in_touch .footer_details ul li svg {
  margin-right: 1rem;
}
.get_in_touch .footer_details ul li a {
  font-size: 1.31vw;
}

.call_panel {
  background: #1c1c1c;
  padding: 40px;
}
.call_panel .row {
  background-color: #e5eff9;
  border-radius: 10px;
  padding: 22px;
}
.call_panel .call_btn {
  font-size: 1.5rem;
  font-weight: 500;
  background: #2870ac;
  color: #fff;
  padding: 1rem;
  border: none;
}
.call_panel .call_btn svg {
  margin-right: 10px;
}

.about_callpanel .call_panel {
  background: #fff;
}

.text_contact {
  display: flex;
  align-items: center;
  gap: 15px;
}
.text_contact p {
  font-size: 1.5rem;
  margin: 0px;
  font-weight: 500;
  font-family: roboto;
}

.input_group {
  margin-bottom: 20px;
}
.input_group textarea {
  height: 288px;
}
.input_group button {
  max-width: 150px;
  background: #ed174b;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  border: none;
  padding: 10px 40px;
  float: right;
}

.input_design {
  width: 100%;
  padding: 10px;
  border: 1px solid #707070;
  outline: none;
}
.input_design::-moz-placeholder {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 600;
  font-family: roboto;
}
.input_design::placeholder {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 600;
  font-family: roboto;
}

.react-multi-carousel-track {
  gap: 20px;
  padding-bottom: 30px !important;
  overflow: visible !important;
}

.react-multi-carousel-dot--active button {
  background: rgba(0, 0, 0, 0.5) !important;
}

.react-multi-carousel-dot-list {
  margin-top: 12px;
}

.react-multi-carousel-dot button {
  border: none !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

li.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgba(0, 0, 0, 0.5) !important;
}

.term_condition_text_box {
  padding: 50px 40px;
}
.term_condition_text_box h3 {
  font-size: 28px;
  margin-top: 20px;
}

.wp-block-table {
  font-size: 1.125rem;
}
.wp-block-table td {
  border: 1px solid;
  padding: 0.5em;
}

.error {
  height: 100%;
  background: #ed174b;
  font-family: roboto;
  color: #fff;
}
.error a {
  color: #fff;
  text-decoration: underline;
}

.please_wait {
  padding: 4rem 0rem;
  text-align: center;
}
.please_wait span {
  color: #ed174b;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
}

.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ed174b;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
  margin: 0px auto;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
.skeleton-loader {
  width: 27vw;
  height: 26.979vw;
  border-radius: 50%;
}

@keyframes pulse {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}
.loading-animation {
  overflow: hidden;
  background: #f3698b;
}

.loading-animation::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  animation: loading 1s infinite;
  background: linear-gradient(to right, transparent, #fa8aa6, transparent);
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.mobileHeader {
  display: none;
}

.celebrityvoices {
  position: relative;
}
.celebrityvoices img {
  width: 100%;
}
.celebrityvoices .celebrityvoicesContent {
  text-align: center;
  position: absolute;
  bottom: 55px;
  right: 0;
  left: 0;
  color: #fff;
}

.searchname ul li {
  color: red;
}

.searchResult {
  position: relative;
  background: #1c1c1c;
}

.shortList {
  position: absolute;
  bottom: 27px;
  left: 0;
  right: 0;
  margin: 0px auto;
  text-align: center;
  justify-content: center;
  display: flex;
}
.shortList svg {
  border-radius: 10px;
}

.voiceBox {
  background: url(https://greatbritish.b-cdn.net/wp-content/uploads/2020/05/flag.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 50px;
  background-repeat: no-repeat;
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
}
.voiceBox .profileImage {
  position: relative;
}
.voiceBox .profileImage img {
  width: 140px;
  height: 140px;
  margin: auto;
  border: 7px solid #fff;
  border-radius: 100%;
  overflow: hidden;
}
.voiceBox .voiceCandidateDetails {
  background: #fff;
  padding-bottom: 30px;
  height: 100%;
}
.voiceBox .voiceCandidateDetails span {
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-family: roboto;
  margin: 10px 0;
  text-align: center;
  background: #2870ac;
  display: block;
  padding: 10px;
}
.voiceBox .voiceCandidateDetails ul li {
  color: #333 !important;
  font-size: 16px;
}

.searchPanel {
  display: flex;
  align-items: center;
  color: #fff;
  gap: 20px;
  font-size: 20px;
  padding-bottom: 30px;
}
.searchPanel p {
  margin: 0px;
}
.searchPanel a {
  background: #fff;
  color: #000;
  padding: 5px 15px;
  font-weight: 700;
  border-radius: 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.featuredVoices {
  background: #1c1c1c;
}
.featuredVoices .heading_panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.featuredVoices .heading_panel h3 {
  color: #fff;
}

.articleBox {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1490196078);
  margin-bottom: 20px;
  height: 100%;
  margin-bottom: 20px;
  background: #fff;
}
.articleBox .articleImage img {
  width: 100%;
}
.articleBox .articleDesc {
  padding: 20px;
}
.articleBox .articleDesc span {
  color: #898989;
  font-size: 12px;
  margin: 20px 0px;
  text-align: left;
}
.articleBox .articleDesc h5 {
  color: #000000;
  font-size: 16px;
  margin: 30px 0px;
  text-align: left;
  display: block;
}
.articleBox .articleDesc h5 a {
  color: #000000;
}
.articleBox .articleDesc h5 a:hover {
  color: #ed174b;
}

.inputGroup {
  display: flex;
  gap: 20px;
  position: relative;
}
.inputGroup i {
  position: absolute;
  z-index: 333;
  left: 26px;
  top: 12px;
  font-size: 19px;
  color: #d3d2d2;
}
.inputGroup input {
  width: 100%;
  padding: 10px 45px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgba(221, 221, 221, 0.8666666667);
  position: relative;
}
.inputGroup input:before {
  content: "/sdf";
  position: absolute;
  left: 0px;
  z-index: 333;
  top: 0;
}

.pagination {
  justify-content: center;
  gap: 10px;
}
.pagination .page-link {
  border-color: #dadada;
  color: #1c1a1a;
  padding: 10px 20px;
  border-radius: 0px !important;
}
.pagination .active > .page-link {
  background: #ed174b;
  color: #fff !important;
}

@media screen and (max-width: 767px) {
  header {
    padding: 10px 10px;
  }
  .mobileHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .desktopHeader {
    display: none;
  }
  .mehuToggleButton {
    display: block;
    row-gap: 10px;
    width: -moz-fit-content;
    width: fit-content;
  }
  .menuLink {
    display: none;
  }
  .menuLink li a {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-weight: 500;
    padding: 20px 10px;
    display: block;
  }
  .logoWrap {
    width: 60%;
    float: right;
  }
  .logoWrap img {
    width: 100%;
  }
  .logoWrapHidden {
    display: block;
  }
  .skeleton-loader {
    width: 80%;
    height: 307px;
  }
  h3 {
    font-size: 26px;
  }
  .section_first .column_second img {
    width: 80%;
    margin: 0px auto;
  }
  .section_first .column_first {
    justify-content: center;
  }
  .section_first .column_first h1 {
    font-size: 58px;
    justify-content: center;
    text-align: center;
  }
  .what_we_do_box {
    padding: 10px 10px;
  }
  .img_box img {
    width: 100%;
  }
  .react-multi-carousel-track {
    gap: 0;
  }
  .we_supply_slider .card {
    box-shadow: none !important;
  }
  .we_supply_slider .card .card_title {
    padding-top: 41px;
    padding-bottom: 22px;
  }
  .we_supply_slider .card .card_title a {
    font-size: 17px;
  }
  .extra-width {
    width: inherit;
    display: grid;
    row-gap: 10px;
  }
  .extra-width .profile_text {
    text-align: center;
  }
  .extra-width .profile_text h3 {
    width: -moz-fit-content;
    width: fit-content;
    display: block;
    margin: 0px auto;
    margin-top: 10px;
  }
  .logoSection {
    padding: 10px 0px;
  }
  .profile_img img {
    height: 137px;
    width: 137px !important;
    margin: 0px auto;
    display: block;
  }
  .card .card_text {
    text-align: center;
  }
  .card .card_text p {
    font-size: 12px;
    line-height: 24px;
  }
  .our_team_red_box {
    padding: 10px;
  }
  .our_team_red_box .lets_talk {
    display: none;
  }
  .our_team_red_box .title_row {
    margin-bottom: 2rem;
    text-align: center;
    margin-top: 2rem;
    text-align: center;
    justify-content: center;
  }
  footer .footer_details ul li a {
    font-size: 14px;
  }
  footer .footer_right .social_media_links {
    justify-content: start;
  }
  footer .footer_right .term_and_condition {
    justify-content: start;
  }
  footer .footer_right .term_and_condition li {
    font-size: 12px;
  }
  footer .footer_right .term_and_condition li a {
    font-size: 12px;
  }
  .page_breadcrumbs {
    height: 50vh;
  }
  .page_breadcrumbs .text_panel_breadcrumbs {
    text-align: center;
    border-bottom: 10px solid #ed174b;
    display: flex;
    align-items: center;
  }
  .page_breadcrumbs .text_panel_breadcrumbs h1 {
    font-size: 58px;
  }
  .text_panel_breadcrumbs_image {
    display: none;
  }
  .about_breadcrumbs {
    height: 50vh;
    overflow: hidden;
  }
  .text_contact {
    display: block;
    text-align: center;
  }
  .text_contact p {
    font-size: 16px;
    margin: 15px 0px;
  }
  .call_panel .call_btn {
    font-size: 16px;
    padding: 10px 16px;
    display: block;
    margin: 0px auto;
  }
  .what_we_do_box .text_box {
    text-align: center;
  }
  .what_we_do_box .text_box h3 {
    font-size: 26px;
  }
  .what_we_do_box .text_box p {
    font-size: 13px;
    line-height: 24px;
  }
  .contact_form_img img {
    width: 100%;
  }
  .get_in_touch {
    margin-bottom: 1rem;
  }
  .get_in_touch .footer_details ul li {
    display: flex;
    padding: 0px;
    gap: 10px;
  }
  .get_in_touch .footer_details ul li svg {
    margin: 0px;
    width: 20px;
  }
  .get_in_touch .footer_details ul li a {
    font-size: 16px;
  }
  .captachaPanel {
    width: 100%;
    float: left;
  }
}
.singleBlogPage {
  position: relative;
}
.singleBlogPage h4 {
  font-weight: 400;
}
.singleBlogPage h4 a {
  color: #ed174b;
}
.singleBlogPage p a {
  color: #ed174b;
}

.blogBredcrumb {
  margin-bottom: 50px;
}

.singleBlogTitle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #ed174b;
  padding: 10px 10px;
  margin: 0px;
  align-items: center;
}
.singleBlogTitle h4 {
  color: #fff;
}
.singleBlogTitle a {
  background: #1c1c1c;
}
.singleBlogTitle p {
  margin: 0px;
}
.singleBlogTitle .postDate {
  color: #fff;
  font-weight: 700 !important;
  background: #2870ac;
  padding: 10px 43px;
  border-radius: 90px;
  width: -moz-fit-content;
  width: fit-content;
  float: right;
}

.newsArticle {
  background: #1c1c1c;
}

.TitleHeader {
  background: #ed174b;
}
.TitleHeader h1 {
  background-color: #1c1c1c;
  padding: 3rem;
  color: #fff;
  border-radius: 15px;
}

.TitleRow h1 {
  font-size: 2.63vw;
  color: #ED174B;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.boxPanel__main {
  text-align: center;
}
.boxPanel__main img {
  height: 250px;
  width: 100%;
  border: 2px solid #E6EDF8;
  -o-object-fit: contain;
     object-fit: contain;
}
.boxPanel__main .voice__descripiton {
  padding-top: 10px;
  text-align: left;
}
.boxPanel__main .voice__descripiton h6 {
  color: #6B7176;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 30px;
}
.boxPanel__main .voice__descripiton p {
  font-size: 16px;
}

.aboutGBV h1 {
  color: #ED174B;
}
.aboutGBV p {
  font-size: 16px;
}
.aboutGBV p a {
  color: #ED174B;
  font-weight: 500;
}

.celebrity__voices__bannersection img {
  width: 100%;
}

.celebrit__voices__text {
  background: #1c1c1c;
}
.celebrit__voices__text h3 a {
  font-size: 2rem;
}
.celebrit__voices__text h5 {
  font-size: 18px;
}
.celebrit__voices__text p {
  color: #fff;
  font-size: 16px;
}/*# sourceMappingURL=main.css.map */